import { DOCUMENT, isPlatformBrowser } from "@angular/common";
import { Inject, Injectable, InjectionToken, Optional, PLATFORM_ID } from "@angular/core";

export const APP_SSR_COOKIES = new InjectionToken<string>('APP_SSR_COOKIES');

@Injectable({ providedIn: 'root' })
export class CookiesService {
  private isBrowser: boolean;

  constructor(
    @Optional() @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>,
    @Optional() @Inject(APP_SSR_COOKIES) private ssrCookies: string
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  get(): string {
    return this.isBrowser ? this.document.cookie : this.ssrCookies;
  }
}
