import { CommonModule, isPlatformBrowser } from "@angular/common";
import { HttpErrorResponse } from "@angular/common/http";
import { Component, Inject, InjectionToken, OnInit, PLATFORM_ID, signal } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef } from "@angular/material/dialog";
import { CBox_PublicRolePermissionMapping } from "@server/services/cbox/public/api/v1/enforcers/entity_permission/types";
import { CBox_PublicSuccessResponse } from "@server/services/cbox/public/api/v1/resources/common/request_base/types";
import { CBox_ActionCreateUserResponse, CBox_ActionUpdateApiUserData, CBox_GetApiUserResponse, CBox_GetSelfUserResponse } from "@server/services/cbox/public/api/v1/resources/user/types";
import { AutoFocusModule } from "primeng/autofocus";
import { ButtonModule } from "primeng/button";
import { CheckboxModule } from "primeng/checkbox";
import { DividerModule } from "primeng/divider";
import { DropdownModule } from "primeng/dropdown";
import { InputGroupModule } from "primeng/inputgroup";
import { InputGroupAddonModule } from "primeng/inputgroupaddon";
import { InputTextModule } from "primeng/inputtext";
import { InputTextareaModule } from "primeng/inputtextarea";
import { MultiSelectModule } from "primeng/multiselect";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { TableModule } from "primeng/table";
import { ApiService, handlePublicApiError } from "src/services/api/api.service";
import { ConfigurationService } from "src/services/configuration/configuration.service";
import { LockersService } from "src/services/lockers/lockers.service";
import { ProfileService } from "src/services/profile/profile.service";
import { ToastService } from "src/services/toast/toast.service";

@Component({
  selector: "app-cbox-profile-user-create",
  templateUrl: "cbox-profile-api-user-edit.component.html",
  styleUrls: ["./cbox-profile-api-user-edit.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    TableModule,
    ReactiveFormsModule,
    InputTextModule,
    ButtonModule,
    DividerModule,
    InputGroupModule,
    InputGroupAddonModule,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    AutoFocusModule,
    DropdownModule,
    MultiSelectModule,
    CheckboxModule,
    ProgressSpinnerModule,
    InputTextareaModule
  ]
})

export class CBoxProfileApiUserEditComponent implements OnInit {

  apiUserCreateStatus = signal<"waiting" | "created" | "creating">("waiting");
  apiUserCreateResponse = signal<CBox_ActionCreateUserResponse | undefined>(undefined);
  userForm: FormGroup;
  userData = signal<CBox_GetSelfUserResponse | undefined>(undefined);
  roles = signal<CBox_PublicRolePermissionMapping | undefined>(undefined);

  get lockersForm(): FormGroup {
    return this.userForm.get("lockers") as FormGroup;
  }

  constructor(
    private profileService: ProfileService,
    private api: ApiService,
    private fb: FormBuilder,
    private lockerService: LockersService,
    private config: ConfigurationService,
    private toastService: ToastService,
    private dialogRef: MatDialogRef<CBoxProfileApiUserEditComponent>,
    @Inject(MAT_DIALOG_DATA) private identifier: string,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>) {
      this.userForm = this.fb.group({
        description: [""],
        role: ["", Validators.required],
      })
    }

  ngOnInit(): void {
    this.init();
  }

  loadData(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.fetchUserData();
    }
  }

  saveUser(): void {
    this.apiUserCreateStatus.set("creating");
    this.api.patch("backend/users/api/" + this.identifier, this.getUpdateStructure()).subscribe(() => {
      this.dialogRef.close(true);
      this.apiUserCreateStatus.set("created");
    }, (e: HttpErrorResponse) => {
      this.apiUserCreateStatus.set("waiting");
      handlePublicApiError(e, this.toastService);
    });
  }

  private async init(): Promise<void> {
    this.profileService.setTitle("Detalii cont");
    const roles = await this.config.getRolePermissonMapping()
    this.roles.set(this.filterRoles(roles));
    this.loadData();
  }

  private fetchUserData(): void {
    this.api.get<CBox_PublicSuccessResponse<CBox_GetApiUserResponse>>("backend/users/api/" + this.identifier).subscribe((response) => {
      const data = response.data;
      this.userForm.patchValue({
        description: data.description,
        role: data.role,
      });
    });
  }

  private filterRoles(roles: CBox_PublicRolePermissionMapping): CBox_PublicRolePermissionMapping {
    return roles.filter((role) => role.canCreate);
  }

  private getUpdateStructure(): CBox_ActionUpdateApiUserData {
    const data: CBox_ActionUpdateApiUserData = {
      identifier: this.identifier,
      role: this.userForm.get("role")?.value,
      description: this.userForm.get("description")?.value,
    };

    return data;
  }
}