import { Component, EventEmitter, Injector, OnInit, OnChanges, Output, Input } from "@angular/core";
import { FormBuilder, Validators, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { FormControlsOf, LockerComponentBase } from "../locker-equipment-base";
import { CBox_AdminEquipmentListItem, CBox_DvrEquipmentData } from "@server/services/cbox/public/api/v1/resources/internal/equipment/types";
import { InputGroupModule } from "primeng/inputgroup";
import { InputGroupAddonModule } from "primeng/inputgroupaddon";
import { InputTextModule } from "primeng/inputtext";
import { CBox_PublicSuccessResponse } from "@server/services/cbox/public/api/v1/resources/common/request_base/types";
import { ApiService } from "src/services/api/api.service";

@Component({
  selector: "app-cbox-admin-dvr-template",
  templateUrl: "./cbox-admin-dvr-template.component.html",
  styleUrls: ["./cbox-admin-dvr-template.component.scss"],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    InputGroupModule,
    InputGroupAddonModule,
    InputTextModule
  ]
})
export class CBoxAdminDvrTemplateComponent extends LockerComponentBase<CBox_DvrEquipmentData> implements OnInit {
  @Input() equipmentToCopy?: CBox_AdminEquipmentListItem;
  @Input() loading!: boolean;
  @Output() onValueChanged = new EventEmitter<FormGroup<FormControlsOf<CBox_DvrEquipmentData>>>();

  constructor(
    private fb: FormBuilder,
    protected inj: Injector,
    private api: ApiService
  ) {
    super(inj);
    this.equipmentForm = this.fb.group({
      dvr: this.fb.group({
        user: ["", Validators.required],
        password: ["", Validators.required],
        connectCode: ["", Validators.required]
      })
    }) as unknown as FormGroup<FormControlsOf<CBox_DvrEquipmentData>>;
  }

  get credentialsForm(): FormGroup {
    return this.equipmentForm.get("dvr") as FormGroup;
  }

  ngOnInit() {
    this.baseInit();
  }

  async init(): Promise<void> {}

  protected patchCopiedEquipmentData(copiedEquipmentData: any): void {
    this.equipmentForm.patchValue({
      dvr: {
        user: copiedEquipmentData?.dvr?.user,
        password: copiedEquipmentData?.dvr?.password,
        connectCode: copiedEquipmentData?.dvr?.connectCode
      }
    });
  }
}