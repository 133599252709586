import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, signal } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { CBox_PublicSuccessResponse } from '@server/services/cbox/public/api/v1/resources/common/request_base/types';
import { AutoFocusModule } from 'primeng/autofocus';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { markFormDirty } from 'src/helpers/functions';
import { ApiService, handlePublicApiError } from 'src/services/api/api.service';
import { ToastService } from 'src/services/toast/toast.service';

@Component({
  selector: 'app-cbox-admin-module-create',
  templateUrl: './cbox-admin-module-create.component.html',
  styleUrls: ["./cbox-admin-module-create.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonModule,
    InputTextModule,
    DropdownModule,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    ProgressSpinnerModule,
    AutoFocusModule
  ]
})

export class CBoxAdminModuleCreateComponent implements OnInit {

  form: FormGroup;
  moduleTypes = signal<any | undefined>(undefined);
  loading = signal(true);

  constructor(
    private api: ApiService,
    private toastService: ToastService,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<CBoxAdminModuleCreateComponent>) {
      this.form = this.fb.group({
        serial: ["", Validators.required],
        moduleTypeId: [undefined, Validators.required]
      });
    }

  ngOnInit(): void {
    this.init();
  }

  create(): void {
    if (this.form.invalid) {
      markFormDirty(this.form);
      return;
    }

    this.loading.set(true);
    this.api.post("backend/internal/modules", this.getCreateStructure()).subscribe(() => {
      this.toastService.showSuccessToast("Confirmare", "Modulul a fost creat cu succes.");
      this.dialogRef.close(true);
    }, (e: HttpErrorResponse) => {
      this.loading.set(false);
      handlePublicApiError(e, this.toastService);
    });
  }

  private async init(): Promise<void> {
    this.loadModuleTypes();
  }

  private loadModuleTypes(): void {
    this.fetchModuleTypes();
  }

  private fetchModuleTypes(): void {
    this.api.get<CBox_PublicSuccessResponse<any>>("backend/internal/modules/types").subscribe((response) => {
      this.moduleTypes.set(response.data);
      this.loading.set(false);
    }, (e: HttpErrorResponse) => {
      this.loading.set(false);
      handlePublicApiError(e, this.toastService);
    })
  }

  private getCreateStructure(): {
    typeId: number;
    serial: string;
  } {
    const f = this.form.value;
    const data = {
      typeId: f.moduleTypeId,
      serial: f.serial
    };

    return data;
  }
}