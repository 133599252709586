<p-toast
  [baseZIndex]="9999"
  position="bottom-center"
  key="global-toast"
></p-toast>

<p-toast
  [baseZIndex]="9999"
  position="top-right"
  key="sticky-toast"
>
  <ng-template let-message pTemplate="message">
    @let report = message.data;
    <div class="flex flex-column align-items-start" style="flex: 1">
      <div class="font-medium text-lg my-1 text-900">
          {{ message.summary }}
      </div>
      <p class="m-0 mb-1">{{ message.detail }}</p>
      <div class="flex align-items-center gap-2">
        @if (reportHasFileType(report, reportFileTypes.PDF)) {
          <p-button
            styleClass="text-xs p-2"
            size="small"
            [ngClass]="{
              'pointer-events-none': reportsService.isDownloadingReport(report, reportFileTypes.PDF),
              'opacity-50': reportsService.isDownloadingReport(report, reportFileTypes.PDF),
            }"
            (click)="downloadReport(report, reportFileTypes.PDF)"
          >
            <div class="flex align-items-center gap-2">
              <span class="pi pi-file-pdf"></span>
              <span class="text-xs font-bold">PDF</span>
            </div>
          </p-button>
        }

        @if (reportHasFileType(report, reportFileTypes.XLSX)) {
          <p-button
            styleClass="text-xs p-2"
            size="small"
            (click)="downloadReport(report, reportFileTypes.XLSX)"
          >
            <div class="flex align-items-center gap-2">
              <span class="pi pi-file-excel"></span>
              <span class="text-xs font-bold">XLSX</span>
            </div>
          </p-button>
        }
      </div>
    </div>
  </ng-template>
</p-toast>
<router-outlet></router-outlet>