import { CommonModule, isPlatformBrowser } from "@angular/common";
import { ChangeDetectionStrategy, Component, HostListener, Inject, InjectionToken, OnInit, PLATFORM_ID } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { RouterOutlet } from "@angular/router";
import { CBox_PublicGetReportListItemData, CBox_PublicReportFileTypes } from "@server/services/cbox/public/api/v1/resources/reports/types";
import { ButtonModule } from "primeng/button";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { ToastModule } from "primeng/toast";
import { ReportsService } from "src/services/reports/reports.service";
import { ToastService } from "src/services/toast/toast.service";

@Component({
  selector: "app-root",
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    ToastModule,
    ButtonModule,
    ProgressSpinnerModule
  ],
  templateUrl: "./app.component.html",
  styleUrl: "./app.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent {

  reportFileTypes = CBox_PublicReportFileTypes;

  constructor(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    // private toastService: ToastService,
    public reportsService: ReportsService,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>
  ) {
    iconRegistry.addSvgIcon(
      "logo",
      sanitizer.bypassSecurityTrustResourceUrl("assets/logo-black.svg")
    );

    iconRegistry.addSvgIcon(
      "logo-small",
      sanitizer.bypassSecurityTrustResourceUrl("assets/logo-small.svg")
    );

    iconRegistry.addSvgIcon(
      "cbox-by-logo",
      sanitizer.bypassSecurityTrustResourceUrl("assets/cbox-by-logo.svg")
    );

    iconRegistry.addSvgIcon(
      "ro-post-logo",
      sanitizer.bypassSecurityTrustResourceUrl("assets/ro-post-logo.svg")
    );
  }

  downloadReport(report: CBox_PublicGetReportListItemData, fileType: CBox_PublicReportFileTypes): void {
    this.reportsService.downloadReport(report, fileType);
  }

  reportHasFileType(report: CBox_PublicGetReportListItemData, fileType: CBox_PublicReportFileTypes): boolean {
    return report.files.some((file) => file.type === fileType);
  }

}
