<div class="dialog-header flex align-items-center justify-content-between w-full">
  <h2>Creare echipament</h2>
  <p-button
    mat-dialog-close
    icon="pi pi-times"
    severity="secondary"
    size="small"
    [rounded]="true"
    [text]="true"
  />
</div>
<mat-dialog-content>
  <form [formGroup]="form" class="grid">
    <div class="col-12">
      <span class="block font-bold mb-1">Tip</span>
      <p-dropdown
        optionLabel="name"
        optionValue="id"
        appendTo="body"
        placeholder="Selectati tipul echipamentului"
        formControlName="typeId"
        styleClass="w-full"
        [options]="equipmentTypes()"
        [loading]="!equipmentTypes()"
        (onChange)="typeSelected($event.value)"
      ></p-dropdown>
    </div>

    @if (selectedType()) {
      <div class="col-12">
        <span class="block font-bold mb-1">Model</span>
        <p-dropdown
          optionLabel="name"
          optionValue="id"
          formControlName="modelId"
          appendTo="body"
          styleClass="w-full"
          placeholder="Selectati modelul echipamentului"
          [options]="selectedType()?.models"
        ></p-dropdown>
      </div>

      <div class="col-12">
        <span class="block font-bold mb-1">Serie</span>
        <input
          class="w-full"
          formControlName="internalSerial"
          placeholder="Introduceți internal serial"
          pInputText
        >
        @let internalSerial = form.get("internalSerial");
        @let internalSerialErrors = internalSerial?.errors;
        @if (internalSerial?.dirty) {
          @if (internalSerialErrors?.["required"]) {
            <small class="text-red-500">Câmpul este obligatoriu</small>
          }
        }
      </div>

      <!-- <div class="col-12">
        <span class="block font-bold mb-1">Internal sequence number</span>
        <input
          class="w-full"
          formControlName="internalSequenceNumber"
          placeholder="Introduceți internal sequence number-ul"
          pInputText
        >
        @let internalSequenceNumber = form.get("internalSequenceNumber");
        @let internalSequenceNumberErrors = internalSequenceNumber?.errors;
        @if (internalSequenceNumber?.dirty) {
          @if (internalSequenceNumberErrors?.["required"]) {
            <small class="text-red-500">Câmpul este obligatoriu</small>
          }
        }
      </div> -->

      <div class="col-12">
        <span class="block font-bold mb-1">Serie producător</span>
        <input
          class="w-full"
          formControlName="manufacturerSerial"
          placeholder="Introduceți manufacturer serial-ul"
          pInputText
        >
        @let manufacturerSerial = form.get("manufacturerSerial");
        @let manufacturerSerialErrors = manufacturerSerial?.errors;
        @if (manufacturerSerial?.dirty) {
          @if (manufacturerSerialErrors?.["required"]) {
            <small class="text-red-500">Câmpul este obligatoriu</small>
          }
        }
      </div>

      <div class="col-12">
        <span class="block font-bold mb-1">Notes</span>
        <textarea
          class="w-full"
          rows="5"
          formControlName="notes"
          placeholder="Notes"
          pInputTextarea
        ></textarea>
      </div>
    }


    @if (selectedType() && form.get("modelId")?.value) {
      @switch(selectedType()?.type) {
        @case (equipmentTypeEnum.DVR) {
          <app-cbox-admin-dvr-template
            class="contents"
            [equipmentToCopy]="equipmentToCopy"
            (onValueChanged)="patchForm($event)"
          ></app-cbox-admin-dvr-template>
        }

        @case (equipmentTypeEnum.ROUTER) {
          <app-cbox-admin-router-template
            class="contents"
            [equipmentToCopy]="equipmentToCopy"
            (onValueChanged)="patchForm($event)"
          ></app-cbox-admin-router-template>
        }
      }
    }

    <!-- @if (form.get("pairWithLocker")?.value === true) {
      <div class="col-12">
        <span class="font-bold block mb-1">Locker</span>
        @let locker = form.get("locker");
        @let lockerErrors = locker?.errors;
        <p-dropdown
          formControlName="lockerId"
          optionLabel="name"
          optionValue="id"
          placeholder="Selectați un locker"
          styleClass="w-full"
          appendTo="body"
          [options]="lockers()"
          [loading]="!lockers()?.length"
        />
        @if (locker?.dirty) {
          @if (lockerErrors?.["required"]) {
            <small class="text-red-500">Vă rugăm să selectați un locker</small>
          } @else {
            <small>Alegeți lockerul la care doriți să asociați echipamentul</small>
          }
        } @else {
          <small>Alegeți lockerul la care doriți să asociați echipamentul</small>
        }
      </div>
    }
    <p-checkbox
      class="col-12"
      formControlName="pairWithLocker"
      label="Asociați cu un locker"
      [binary]="true"
      (onChange)="pairWithLockerToggled()"
    ></p-checkbox> -->
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    pButton
    class="w-full"
    label="Adauga"
    (click)="create()"
  ></button>
</mat-dialog-actions>
@if (loading() === true) {
  <div class="blurred-div">
    <p-progressSpinner class="blurred-div-spinner"></p-progressSpinner>
  </div>
}