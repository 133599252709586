import { Component, EventEmitter, Injector, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { FormControlsOf, LockerComponentBase } from "../locker-equipment-base";
import { CBox_AdminEquipmentListItem, CBox_RouterEquipmentData } from "@server/services/cbox/public/api/v1/resources/internal/equipment/types";
import { InputTextModule } from "primeng/inputtext";
import { InputGroupModule } from "primeng/inputgroup";
import { InputGroupAddonModule } from "primeng/inputgroupaddon";

@Component({
  selector: "app-cbox-admin-router-template",
  templateUrl: "./cbox-admin-router-template.component.html",
  styleUrls: ["./cbox-admin-router-template.component.scss"],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    InputTextModule,
    InputGroupModule,
    InputGroupAddonModule
  ]
})
export class CBoxAdminRouterTemplateComponent extends LockerComponentBase<CBox_RouterEquipmentData> implements OnInit {
  @Input() equipmentToCopy?: CBox_AdminEquipmentListItem;
  @Input() loading!: boolean;
  @Output() onValueChanged = new EventEmitter<FormGroup<FormControlsOf<CBox_RouterEquipmentData>>>();

  constructor(
    private fb: FormBuilder,
    protected inj: Injector
  ) {
    super(inj);
    this.equipmentForm = this.fb.group({
      adminUser: ["", Validators.required],
      adminPassword: ["", Validators.required],
      ssid: ["", Validators.required],
      wifiPassword: ["", Validators.required],
      sim: this.fb.group({
        phoneNumber: ["", Validators.required],
        simNumber: ["", Validators.required],
        ip: ["", Validators.required],
        subscription: this.fb.group({
          name: ["", Validators.required],
        }),
      })
    }) as FormGroup<FormControlsOf<CBox_RouterEquipmentData>>;
  }

  get simForm(): FormGroup {
    return this.equipmentForm.get("sim") as FormGroup;
  }

  get subscriptionForm(): FormGroup {
    return this.simForm.get("subscription") as FormGroup;
  }

  ngOnInit() {
    this.baseInit();
  }

  async init(): Promise<void> {}

  protected patchCopiedEquipmentData(copiedEquipmentData: any): void {
    this.equipmentForm.patchValue({
      adminUser: copiedEquipmentData?.adminUser,
      adminPassword: copiedEquipmentData?.adminPassword,
      ssid: copiedEquipmentData?.ssid,
      wifiPassword: copiedEquipmentData?.wifiPassword,
      sim: {
        phoneNumber: copiedEquipmentData?.sim.phoneNumber,
        simNumber: copiedEquipmentData?.sim.simNumber,
        ip: copiedEquipmentData?.sim.ip,
        subscription: {
          name: copiedEquipmentData?.sim.subscription.name,
        },
      },
    });
  }
}