import { CommonModule } from "@angular/common";
import { Component, OnInit, signal } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { CBox_ActionUpdateSelfUserData, CBox_ActionUpdateSelfUserResponse } from "@server/services/cbox/public/api/v1/resources/user/types";
import { ButtonModule } from "primeng/button";
import { InputGroupModule } from "primeng/inputgroup";
import { InputGroupAddonModule } from "primeng/inputgroupaddon";
import { InputTextModule } from "primeng/inputtext";
import { ApiService, handlePublicApiError } from "src/services/api/api.service";
import { InputTextareaModule } from "primeng/inputtextarea";
import { HttpErrorResponse } from "@angular/common/http";
import { CBox_PublicSuccessResponse } from "@server/services/cbox/public/api/v1/resources/common/request_base/types";
import { Message } from "primeng/api";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { matchOtherValidator } from "src/validators/match-other-control-validator";
import { MatIconModule } from "@angular/material/icon";
import { CheckboxModule } from "primeng/checkbox";
import { MessagesModule } from "primeng/messages";
import { ToastService } from "src/services/toast/toast.service";

@Component({
  selector: "app-cbox-profile-me-security",
  templateUrl: "./cbox-profile-me-security.component.html",
  styleUrls: ["./cbox-profile-me-security.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    InputGroupModule,
    InputGroupAddonModule,
    InputTextModule,
    ReactiveFormsModule,
    ButtonModule,
    InputTextareaModule,
    ProgressSpinnerModule,
    MatIconModule,
    CheckboxModule,
    MessagesModule
  ]
})

export class CBoxProfileMeSecurityComponent implements OnInit {

  passwordForm: FormGroup;
  loading = signal(false);
  disconnectAllInfo = signal<Message[]>([
    {
      severity: "warn",
      detail: "După resetarea parolei, veți fi deconectat de la toate dispozitivele care folosesc acest cont cu excepția dispozitivului actual."
    }
  ])

  constructor(
    private api: ApiService,
    private fb: FormBuilder,
    private toastService: ToastService) {
      this.passwordForm = this.fb.group({
        old: [""],
        new: ["", [Validators.required, Validators.minLength(8)]],
        confirmPassword: ["", matchOtherValidator("new")],
        disconnectAll: [false]
      });
    }

  ngOnInit() {}

  resetPassword(): void {
    let url = "backend/users/me";
    if (this.passwordForm.get("disconnectAll")?.value) {
      url += "?disconnectAll=true";
    }
    this.api.patch<CBox_PublicSuccessResponse<CBox_ActionUpdateSelfUserResponse>>(
      url, this.getResetStructure()).subscribe(() => {
        this.loading.set(false);
        this.toastService.showSuccessToast("Confirmare", "Parola a fost resetată cu succes.");
        this.passwordForm.reset();
    }, (e: HttpErrorResponse) => {
      this.loading.set(false);
      handlePublicApiError(e, this.toastService);
    });
  }

  private getResetStructure(): CBox_ActionUpdateSelfUserData {
    return {
      password: {
        old: this.passwordForm.value.old,
        new: this.passwordForm.value.new
      }
    }
  }
}