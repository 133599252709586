import { CommonModule, isPlatformBrowser } from "@angular/common";
import { HttpErrorResponse } from "@angular/common/http";
import { Component, Inject, InjectionToken, OnInit, PLATFORM_ID, signal } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef } from "@angular/material/dialog";
import { CBox_PublicSuccessResponse } from "@server/services/cbox/public/api/v1/resources/common/request_base/types";
import { ButtonModule } from "primeng/button";
import { DropdownModule } from "primeng/dropdown";
import { InputTextModule } from "primeng/inputtext";
import { MessagesModule } from "primeng/messages";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { markFormDirty } from "src/helpers/functions";
import { ApiService, handlePublicApiError } from "src/services/api/api.service";
import { ToastService } from "src/services/toast/toast.service";

@Component({
  selector: "app-cbox-admin-locker-create-confirm-dialog",
  templateUrl: "./cbox-admin-locker-create-confirm-dialog.component.html",
  styleUrls: ["./cbox-admin-locker-create-confirm-dialog.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonModule,
    InputTextModule,
    DropdownModule,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    ProgressSpinnerModule,
    DropdownModule,
    MessagesModule
  ]
})

export class CBoxAdminLockerCreateConfirmDialogComponent implements OnInit {

  loading = signal(false);

  constructor(
    private api: ApiService,
    private toastService: ToastService,
    private dialogRef: MatDialogRef<CBoxAdminLockerCreateConfirmDialogComponent>) {}

  ngOnInit(): void {}

  create(): void {
    this.loading.set(true);
    this.api.post("backend/internal/lockers", {
      serial: "CB"
    }).subscribe(() => {
      this.toastService.showSuccessToast("Confirmare", "Locker-ul a fost creat cu succes!");
      this.dialogRef.close();
      this.loading.set(false);
    }, (e: HttpErrorResponse) => {
      this.loading.set(false);
      handlePublicApiError(e, this.toastService);
    });
  }
}