<form [formGroup]="filtersForm" class="grid align-items-end row-gap-1 mt-2">
  <div class="col-12 lg:col-3">
    <span class="font-bold block mb-2">Interval căutare</span>
    <p-calendar
      styleClass="w-full"
      dateFormat="dd/mm/yy"
      selectionMode="range"
      formControlName="interval"
      [showIcon]="true"
      [showOnFocus]="true"
      [placeholder]="'Data de la'"
    ></p-calendar>
  </div>

  <div class="col-12 sm:col-3">
    <span class="font-bold block mb-1">Lockere</span>
    <p-multiSelect
      formControlName="lockers"
      optionLabel="name"
      optionValue="id"
      placeholder="Filtrează lockere"
      styleClass="w-full"
      appendTo="body"
      emptyFilterMessage="Nu există lockere"
      [options]="lockers()"
      [loading]="!lockers()"
    />
  </div>

  <div class="col-12 sm:col-3">
    <span class="font-bold block mb-1">Agenți</span>
    <p-multiSelect
      formControlName="agents"
      optionLabel="name"
      optionValue="identifier"
      placeholder="Filtrează agenti"
      styleClass="w-full"
      appendTo="body"
      emptyFilterMessage="Nu există lockere"
      [options]="agents()"
      [loading]="!agents()"
    />
  </div>

  <div class="
    col-12 flex-order-0
    lg:flex-order-1 lg:col-3
    text-right
  ">
    <p-button
      label="Generează raport"
      icon="pi pi-file-plus"
      (onClick)="generateReport()"
    ></p-button>
  </div>
</form>

<p-table
  ngSkipHydration
  styleClass="p-datatable-sm"
  currentPageReportTemplate="{first} - {last} din {totalRecords} rapoarte"
  paginatorLocale="ro"
  paginatorPosition="both"
  [first]="(filtersForm.value.page - 1) * filtersForm.value.pageSize"
  [totalRecords]="totalCount()"
  [lazy]="true"
  [paginator]="true"
  [rows]="filtersForm.get('pageSize')?.value"
  [value]="data()"
  [rowsPerPageOptions]="[25, 50, 100]"
  [showCurrentPageReport]="true"
  (onPage)="pageChanged($event)"
>
  <ng-template pTemplate="header">
    @if (loading()) {
      <tr>
        <td colspan="6">
          <p-progressBar mode="indeterminate" [style]="{'height': '6px'}" />
        </td>
      </tr>
    }
    <tr>
      <th>Agent</th>
      <th>Locker</th>
      <th>Perioadă activitate</th>
      <th class="text-center">Colete expirate preluate</th>
      <th class="text-center">Colete preluate</th>
      <th class="text-center">Colete livrate</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-element>
    <tr class="hover:surface-100 cursor-pointer" (click)="openSessionInfo(element)">
      <td>
        <p class="text-xs text-gray-400 m-0">{{ element.agent.identifier }}</p>
        <b class="text-lg">{{ element.agent.name }}</b>
        <p class="text-xs m-0">
          {{ element.agent.phone }}
          <span>
            &#x2022;
            {{ element.agent.email }}
          </span>
        </p>
      </td>
      <td>
        <div class="flex flex-column">
          <div>
            <small class="text-gray-400 text-xs">#{{ element.locker.identifier }}</small><br>
            <b>{{ element.locker.name }}</b>
            <br>
            <small>{{ lockerCleanAddress(element.locker.addressParts) }}</small>
          </div>
        </div>
      </td>
      <td>
        @let activityStart = element.activity.start;
        @let activityEnd = element.activity.end;
        @if (activityStart) {
          <span class="m-0">
            {{ activityStart | date: 'dd MMMM HH:mm:ss' }}
          </span>
        } @else {
          necunoscut
        } - @if (activityEnd) {
          <span class="m-0">
            {{ activityEnd | date: 'dd MMMM HH:mm:ss' }}
          </span>
        } @else {
          necunoscut
        }
      </td>
      <td class="text-center">
        {{ getActionParcelCount(element, agentActions.COURIER_RETRIEVE_EXPIRED) }}
      </td>
      <td class="text-center">
        {{ getActionParcelCount(element, agentActions.COURIER_TAKE_ORDERS) }}
      </td>
      <td class="text-center">
        {{ getActionParcelCount(element, agentActions.COURIER_PUT_ORDERS) }}
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
      @if (!loading()) {
      <tr>
        <td colspan="6">
          <p class="text-center text-gray-400 text-xl font-bold">Nu există rapoarte</p>
        </td>
      </tr>
    }
  </ng-template>
</p-table>