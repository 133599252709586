<div class="dialog-header flex align-items-center justify-content-between w-full">
  <h2>Selectați tipul raportului</h2>
  <p-button
    mat-dialog-close
    size="small"
    icon="pi pi-times"
    severity="secondary"
    [rounded]="true"
    [text]="true"
  />
</div>
<mat-dialog-content>
  <div class="flex flex-column gap-2 pb-2">
    <div class="flex align-items-center">
      <p-radioButton
        value="lockers"
        [formControl]="reportType"
        inputId="lockers"/>
      <label for="lockers" class="ml-2">Raport activitate locker</label>
    </div>

    <div class="flex align-items-center">
      <p-radioButton
        value="agents"
        [formControl]="reportType"
        inputId="agent"/>
      <label for="agent" class="ml-2">Raport activitate agent</label>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <p-button
    mat-dialog-close
    label="Anulează"
    size="small"
    [text]="true"
    severity="secondary"
  />
  <p-button
    label="Continuă"
    size="small"
    [mat-dialog-close]="reportType.value"
  />
</mat-dialog-actions>