<h2 class="mb-0">Resetare parolă</h2>
<small class="text-gray-600">Resetează oricând parola contului tău.</small>
<form onsubmit="return false" class="flex flex-column gap-4 w-30rem relative mt-4" [formGroup]="passwordForm" (submit)="resetPassword()">
  <div>
    <span class="font-bold block mb-1">Parolă veche</span>
    <input
      type="password"
      autocomplete="off"
      class="w-full"
      formControlName="old"
      placeholder="Parolă"
      pInputText
    >
  </div>

  <div>
    <span class="font-bold block mb-1">Parolă nouă</span>
    <input
      type="password"
      autocomplete="off"
      class="w-full"
      formControlName="new"
      placeholder="Parolă"
      pInputText
    >
    @let newPassword = passwordForm.get("new");
    @let newPasswordErrors = newPassword?.errors;
    @if (newPassword?.dirty && newPassword?.touched) {
      @if (newPasswordErrors?.["minlength"]) {
        @let min = newPasswordErrors?.["minlength"]?.requiredLength;
        <small class="text-red-500">
          Parola trebuie să aibă cel puțin {{ min }} caractere
        </small>
      } @else if (newPasswordErrors?.["required"]) {
        <small class="text-red-500">
          Parola este obligatorie
        </small>
      }
    }
  </div>

  <div>
    <span class="font-bold block mb-1">Confirmare parolă nouă</span>
    <input
      type="password"
      autocomplete="off"
      class="w-full"
      formControlName="confirmPassword"
      placeholder="Parolă"
      pInputText
    >
    @let passwordConfirm = passwordForm.get("confirmPassword");
    @let passwordConfirmErrors = passwordConfirm?.errors;
    @if (passwordConfirmErrors?.["matchOther"]) {
      <small class="text-red-500">
        Parolele nu coincid
      </small>
    } @else if (passwordConfirmErrors?.["required"]) {
      <small class="text-red-500">
        Confirmarea parolei este obligatorie
      </small>
    }
  </div>

  <div>
    <p-checkbox
      [binary]="true"
      formControlName="disconnectAll"
      label="Deconectează-mă de la toate dispozitivele"
    ></p-checkbox>
  </div>

  @if (passwordForm.get("disconnectAll")?.value) {
    <p-messages
      [value]="disconnectAllInfo()"
      [closable]="false"
      [enableService]="false"
    ></p-messages>
  }

  <p-button
    class="align-self-start"
    size="small"
    severity="primary"
    label="Resetează parola"
    type="submit"
    [disabled]="loading() || passwordForm.invalid"
    (click)="$event.preventDefault(); resetPassword()"
  ></p-button>

  @if (loading() === true) {
    <div class="blurred-div">
      <p-progressSpinner
        ariaLabel="loading"
        class="blurred-div-spinner"
      ></p-progressSpinner>
    </div>
  }
</form>


