import { ApplicationConfig, LOCALE_ID, provideZoneChangeDetection } from '@angular/core';
import { NoPreloading, PreloadingStrategy, provideRouter, withPreloading, withViewTransitions } from '@angular/router';

import { routes } from './app.routes';
import { provideClientHydration, withEventReplay, withHttpTransferCacheOptions } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import localRo from '@angular/common/locales/ro';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { RoPaginatorIntlComponent } from '../shared/paginator/ro-paginator.component';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MessageService } from 'primeng/api';
import { CookiesService } from 'src/services/cookies/cookies.service';
import { CredentialsInterceptor } from 'src/services/interceptors/credentials.interceptor';
registerLocaleData(localRo);


export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({
      eventCoalescing: true
    }),
    { provide: LOCALE_ID, useValue: "ro-RO" },
    provideRouter(routes,
      withViewTransitions(),
      withPreloading(NoPreloading),
    ),
    MessageService,
    CookiesService,
    provideClientHydration(
      withHttpTransferCacheOptions({
        includeRequestsWithAuthHeaders: true,
      }),
      withEventReplay(),
    ),
    provideAnimationsAsync(),
    provideHttpClient(
      withFetch(),
      withInterceptorsFromDi(),
    ),
    provideNativeDateAdapter(),
    { provide: MatPaginatorIntl, useClass: RoPaginatorIntlComponent },
    { provide: HTTP_INTERCEPTORS, useClass: CredentialsInterceptor, multi: true },
    provideAnimationsAsync()
  ]
};