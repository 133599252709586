import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Inject, Injectable, InjectionToken, PLATFORM_ID } from "@angular/core";
import { CookiesService } from "../cookies/cookies.service";
import { isPlatformBrowser } from "@angular/common";
import { Observable } from "rxjs";
import { ApiService } from "../api/api.service";

@Injectable({ providedIn: 'root' })
export class CredentialsInterceptor implements HttpInterceptor {

  constructor(
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>,
    private cookiesService: CookiesService,
    private api: ApiService,
  ){}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let newReq: HttpRequest<any>;
    const isBrowser = isPlatformBrowser(this.platformId);

    if (!isBrowser && !req.url.includes("assets")) {
      const cookies = this.cookiesService.get();
      const url = req.url.replace(/backend/g, this.api.url);
      newReq = req.clone({
        url: url,
        setHeaders: {
          Cookie: cookies,
        },
      });
    } else {
      newReq = req.clone({
        withCredentials: true,
      });
    }
    return next.handle(req);
  }

}