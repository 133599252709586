import { CommonModule, isPlatformBrowser } from "@angular/common";
import { HttpErrorResponse } from "@angular/common/http";
import { Component, Inject, InjectionToken, OnInit, PLATFORM_ID, signal } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef } from "@angular/material/dialog";
import { CBox_PublicRolePermissionMapping } from "@server/services/cbox/public/api/v1/enforcers/entity_permission/types";
import { CBox_PublicSuccessResponse } from "@server/services/cbox/public/api/v1/resources/common/request_base/types";
import { CBox_GetLockerShortData } from "@server/services/cbox/public/api/v1/resources/locker/types";
import { CBox_ActionCreateUserData, CBox_ActionCreateUserResponse, CBox_GetSelfUserResponse } from "@server/services/cbox/public/api/v1/resources/user/types";
import { FormattedPermission } from "app/profile/agents/types";
import { Message } from "primeng/api";
import { AutoFocusModule } from "primeng/autofocus";
import { ButtonModule } from "primeng/button";
import { CheckboxModule } from "primeng/checkbox";
import { DividerModule } from "primeng/divider";
import { DropdownModule } from "primeng/dropdown";
import { InputGroupModule } from "primeng/inputgroup";
import { InputGroupAddonModule } from "primeng/inputgroupaddon";
import { InputTextModule } from "primeng/inputtext";
import { InputTextareaModule } from "primeng/inputtextarea";
import { MessagesModule } from "primeng/messages";
import { MultiSelectModule } from "primeng/multiselect";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { TableModule } from "primeng/table";
import { ApiService, handlePublicApiError } from "src/services/api/api.service";
import { ConfigurationService } from "src/services/configuration/configuration.service";
import { LockersService } from "src/services/lockers/lockers.service";
import { ProfileService } from "src/services/profile/profile.service";
import { ToastService } from "src/services/toast/toast.service";
import { UserService } from "src/services/user/user.service";

@Component({
  selector: "app-cbox-profile-platform-user-create",
  templateUrl: "cbox-profile-platform-user-create.component.html",
  styleUrls: ["./cbox-profile-platform-user-create.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    TableModule,
    ReactiveFormsModule,
    InputTextModule,
    ButtonModule,
    DividerModule,
    InputGroupModule,
    InputGroupAddonModule,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    AutoFocusModule,
    DropdownModule,
    MultiSelectModule,
    CheckboxModule,
    ProgressSpinnerModule,
    InputTextareaModule,
    MessagesModule
  ]
})

export class CBoxProfilePlatformUserCreateComponent implements OnInit {

  userCreateStatus = signal<"waiting" | "created" | "creating">("waiting");
  userCreateResponse = signal<CBox_ActionCreateUserResponse | undefined>(undefined);
  userForm: FormGroup;
  userData = signal<CBox_GetSelfUserResponse | undefined>(undefined);
  permissions = signal<FormattedPermission[] | undefined>(undefined);
  roles = signal<CBox_PublicRolePermissionMapping | undefined>(undefined);
  zones = signal<Record<number, { id: string; name: string }>[]>([]);
  zonesFetched = signal(false);

  autoGeneratedIdentifierMessage = signal<Message[]>([{
    severity: "info",
    detail:
        `Identificatorul agentului va fi generat automat de sistemul nostru.
        Acest cod va fi folosit în sistem pentru afișarea informațiilor
        legate de acest agent. În cazul în care doriți să atribuiți un
        identificator personalizat, vă rugăm să bifați căsuța din formular.`
  }]);

  get lockersForm(): FormGroup {
    return this.userForm.get("lockers") as FormGroup;
  }

  constructor(
    private profileService: ProfileService,
    private api: ApiService,
    private fb: FormBuilder,
    private userService: UserService,
    private config: ConfigurationService,
    private toastService: ToastService,
    private dialogRef: MatDialogRef<CBoxProfilePlatformUserCreateComponent>,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>) {
      this.userForm = this.fb.group({
        name: ["", Validators.required],
        email: ["", Validators.required],
        phone: ["", Validators.required],
        notes: [""],
        zoneId: [undefined, Validators.required],
        role: ["", Validators.required],
        identifier: [{
          value: "",
          disabled: true
        }, Validators.required],
        customIdentifier: [false],
      })
    }

  ngOnInit(): void {
    this.init();
  }

  loadData(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.fetchZones();
      this.fetchUserData();
    }
  }

  toggleAllLockers(): void {
    const f = this.userForm;
    if (f.get("lockers.all")?.value) {
      f.get("lockers.specific")?.disable();
      f.get("lockers.specific")?.patchValue([], {
        emitEvent: false
      })
    } else {
      f.get("lockers.specific")?.enable();
    }
  }

  toggleCustomIdentifier(): void {
    const f = this.userForm;
    const identifier = f.get("identifier");
    if (f.get("customIdentifier")?.value) {
      identifier?.enable();
    } else {
      identifier?.disable();
    }
  }

  saveUser(): void {
    this.userCreateStatus.set("creating");
    this.api.post("backend/users", this.getCreateStructure()).subscribe(() => {
      this.dialogRef.close(true);
      this.userCreateStatus.set("created");
    }, (e: HttpErrorResponse) => {
      this.userCreateStatus.set("created");
      handlePublicApiError(e, this.toastService);
    });
  }

  private async init(): Promise<void> {
    const roles = await this.config.getRolePermissonMapping()
    this.roles.set(this.filterRoles(roles));
    this.loadData();
  }

  private fetchZones(): void {
    this.api.get<CBox_PublicSuccessResponse<(CBox_GetLockerShortData & {
      zones: {
        id: string;
        name: string;
      }[]
    })[]>>("backend/locker/identifiers?includeZones=true").subscribe((response) => {
      const zones: {id: string; name: string}[] = [];
      for (const locker of response.data) {
        for (const zone of locker.zones) {
          if (!zones.find(z => z.id === zone.id)) {
            zones.push({id: zone.id, name: zone.name});
          }
        }
      }
      this.zones.set(zones);
      this.zonesFetched.set(true);
    }, (e: HttpErrorResponse) => {
      handlePublicApiError(e, this.toastService);
    });
  }

  private fetchUserData(): void {
    this.userService.getData();
  }

  private filterRoles(roles: CBox_PublicRolePermissionMapping): CBox_PublicRolePermissionMapping {
    return roles.filter((role) => role.canCreate);
  }

  private getCreateStructure(): CBox_ActionCreateUserData {
    const f = this.userForm.value;
    const data: CBox_ActionCreateUserData = {
      name: f.name,
      email: f.email,
      phone: f.phone,
      role: f.role,
      zoneId: f.zoneId,
      identifier: f.identifier,
      notes: f.notes,
    };

    return data;
  }
}